// *********************************************************************************************************************
// ************************************** Jugendddorf Warburg ****************************************
// *********************************************************************************************************************

$color_primary: #ea690b;
$color_secondary: #00519c;
$navSpritePosition: 0; // 0 = orange icons on nav hover + active, (-1000 would be blue)

.brand-petrus-damian-schule { display: none; } // Hide brand of Jugenddorf Warburg


// *********************************************************************************************************************
// ************************************** Color Primary ****************************************
// *********************************************************************************************************************

div.category, div.post-label {
	background: $color_primary;
}

article.type-page,
article.category-allgemein {
	header:after { background-color: $color_primary; }
	div.category, div.post-label { background-color: $color_primary; }
}


div.announcements {
	header {
		background: $color_primary;
	}
}

div.downloads {
	//
	// Header
	//
	header {
		background: $color_primary;
	}
}

#main-nav-hamburger {
	background: $color_primary;
}

// *********************************************************************************************************************
// Site navigation (main navigation)
//
nav#site-navigation {
	#primary-menu {
		ul {
			//
			// Active, hover, focus
			//
			li.current-page-ancestor a,
			li.current-menu-item a,
			a:hover {
				background-position: $navSpritePosition center; // -1000 = blue, 0 = orange
			}
		}

		// Hover
		li.current-page-ancestor > a,
		li.current-menu-item > a,
		a:hover {
			color: $color_primary;
		}
	}
}

//
// Footer
//
footer#colophon {
	// *********************************************************************************************************************
	// Sponsors
	//
	div.footer-cell {
		a {
			color: $color_primary;
		}
	}
}

// *********************************************************************************************************************
// Site navigation (main navigation)
//
nav#site-navigation {
	ul {
		//
		// Active, hover, focus
		//
		li.current-page-ancestor a,
		li.current-menu-item a,
		a:hover {
			color: $color_primary;
		}
	}
}

div#main-slider {
	//
	// Soliloquy slider
	//
	div.soliloquy-container {
		//
		// Caption area (orange slanted square thing, background for caption)
		//
		div.soliloquy-caption {
			background: $color_primary;
		}
	}
}

div.announcements {
	//
	// Main
	//
	main {
		&:after { background-color: $color_primary; } // replaces "%slanted-bar-orange:after"
	}
}

div.downloads {
	//
	// Main
	//
	main {
		&:after {
			background-color: $color_primary;
		}
		// replaces "%slanted-bar-orange:after"
	}
}


// *********************************************************************************************************************
// Popup Maker
// Most styles come from Popup Maker Theme Jugenddorf2.0
// Some additional styles here
.pum {
	.pum-container {
		padding-right: 50px !important;
	}
	a {
		color: $color_primary;
	}
}


// *********************************************************************************************************************
// ************************************** Color Secondary ****************************************
// *********************************************************************************************************************

//
// Hint
//
.hint {
	color: $color_secondary;
	&:before {
		background: $color_secondary;
	}
}